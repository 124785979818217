var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"bd012291890aa32ae4d99d7bdaabf712c8055498"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const getEnv = () => {
  if (process.env.NODE_ENV === 'development') return 'local';
  if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'live') return 'development';
  return 'live';
};

const sentryInternalErrors = [
  /Non-Error exception captured/,
  /Non-Error promise rejection/,
  /currentTarget, detail, isTrusted, target/,
  /.*This Suspense boundary received an update before it finished hydrating.*/,
];

Sentry.init({
  environment: getEnv(),
  beforeSend: (event, hint) => {
    const exception =
      event.exception && event.exception.values && event.exception.values[0];
    if (exception) {
      for (let errorRegex of sentryInternalErrors) {
        if (errorRegex.test(exception.value)) {
          return null;
        }
      }
    }

    event.extra = { ...event.extra, message: hint.originalException };
    return event;
  },
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Non-Error promise rejection',
    'currentTarget, detail, isTrusted, target',
    'CustomEvent: Non-Error promise rejection captured',
    'Extension context invalidated',
    'Error: Extension context invalidated',
    'SecurityError: Blocked a frame with origin',
    'TypeError: null is not an object ',
    'window.localStorage.getItem',
    'Failed to fetch',
    'chrome-extension',
    'Load failed',
    'AbortError',
    "ReferenceError: Can't find variable: _AutofillCallbackHandler",
    'NotAllowedError',
    '<unknown>',
    'Invariant: attempted to hard navigate to the same URL',
    'TypeError',
    /.*This Suspense boundary received an update before it finished hydrating.*/,
    'Beacon API cannot load', // clarity qouta error
    'Failed to load resource: The network connection was lost.',
    'Fetch API cannot load',
    'failed: The operation couldn’t be completed.',
    'ResizeObserver loop completed with undelivered notifications',
    'Blocked a frame with origin',
    'Received status code 502',
    'Received status code 500',
    'Request failed with status code 400',
    'Could not load "onion"',
    "Failed to execute 'insertBefore'",

    // caused by apple's safari adblocker for services like doubleclick
    'due to access control checks.',
    'Blocked by content extension',

    // react minified errors
    'https://reactjs.org/docs/error-decoder.html?invariant=421',
    'https://reactjs.org/docs/error-decoder.html?invariant=418',
    'https://reactjs.org/docs/error-decoder.html?invariant=423',
    'Uncaught Error: Minified React error #418',
    'Error: Minified React error #418',
    'Uncaught Error: Minified React error #423',
    'Error: Minified React error #423',
  ],
  ignoreUrls: [
    /extensions\//,
    /^chrome:\/\//i,
    /^moz-extension:\/\//i,
    /^chrome-extension:\/\//i,
    /script\.hotjar.com/,
  ],
  dsn:
    SENTRY_DSN ||
    'https://54ca5817116d425c89b47cfe11d48ca5@o32955.ingest.sentry.io/1766624',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
